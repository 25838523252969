import React from "react";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  building: getIcon("ic_dropbox"),
  shop: getIcon("ic_shop_icon"),
  users: getIcon("ic_user_group"),
  // settings: getIcon('ic_settings'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      // {
      //   title: "dashboard",
      //   path: PATH_DASHBOARD.general.newDashboard,
      //   icon: ICONS.dashboard,
      //   id: "new-dashboard",
      // },
      {
        title: "Incidents",
        path: PATH_DASHBOARD.general.incidents,
        icon: ICONS.analytics,
        id: "em-incident",
      },
      {
        title: "Cashiers",
        path: PATH_DASHBOARD.general.cashiers,
        icon: ICONS.users,
        id: "em-cashiers",
      },
      {
        title: "Products",
        path: PATH_DASHBOARD.general.products,
        icon: ICONS.ecommerce,
        id: "em-products",
      },
    ],
  },
  //--------------------------
  {
    subheader: "account",
    items: [
      {
        title: "Profile",
        path: PATH_DASHBOARD.general.profile,
        icon: ICONS.user,
        id: "em-profile",
      },
      // {
      //   title: "Notifications",
      //   path: PATH_DASHBOARD.general.notification,
      //   icon: ICONS.mail,
      //   id: "em-notifications",
      // },
    ],
  },
  {
    subheader: "Report",
    items: [
      {
        title: "Report",
        path: PATH_DASHBOARD.general.report,
        icon: ICONS.mail,
        id: "em-report",
      },
      // {
      //   title: "Notifications",
      //   path: PATH_DASHBOARD.general.notification,
      //   icon: ICONS.mail,
      //   id: "em-notifications",
      // },
    ],
  },
];

export default sidebarConfig;
