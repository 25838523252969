// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  updatePassword: path(ROOTS_AUTH, "/update-success"),
  newPassword: path(ROOTS_AUTH, "/new-password/:token"),
  userWelcome: path(ROOTS_AUTH, "/user-welcome/:id"),
};

export const PATH_PAGE = {
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    profile: path(ROOTS_DASHBOARD, "/profile"),
    report: path(ROOTS_DASHBOARD, "/report"),
    incidents: path(ROOTS_DASHBOARD, "/incidents"),
    incidentDetails: path(ROOTS_DASHBOARD, "/incident-details"),
    incidentNew: path(ROOTS_DASHBOARD, "/incident-new"),
    incidentDetailsNew: path(ROOTS_DASHBOARD, "/incident-details-new"),
    incidentLatest: path(ROOTS_DASHBOARD, "/incident-latest"),
    cashiers: path(ROOTS_DASHBOARD, "/cashiers"),
    cashierCreate: path(ROOTS_DASHBOARD, "/cashier-create"),
    products: path(ROOTS_DASHBOARD, "/products"),
    productCreate: path(ROOTS_DASHBOARD, "/product-create"),
  },
  user: {
    profile: path(ROOTS_DASHBOARD, "/profile"),
    report: path(ROOTS_DASHBOARD, "/report"),
  },
};

export const PATH_DOCS = "/docs";
